.app-logo img {
    border-radius: 0px !important;
}

body {
    background-color: #fff;
    color: #444444 !important;
    font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.5;
}

.navbar-nav li.nav-item > .nav-link,
.navbar-nav li.nav-item > a {
    padding: 24px 0 25px;
    color: #666;
}

.app-main-header {
    background-color: #ffffff !important;
    color: #000 !important;
}

.app-header.app-header-horizontal {
    border-bottom: 1px solid #dedede;
}

ul.nav-menu li button,
ul.nav-menu li a {
    color: #666;
}

.jr-menu-icon .menu-icon {
    background-color: #be1e2d;
}

.logo_bg {
    background-color: #be1e2d !important;
    text-decoration: none !important;
    text-transform: inherit !important;
}

button.logo_bg:hover {
    text-decoration: none !important;
    background-color: #900714 !important;
}

a {
    text-decoration: none !important;
}

.side-nav {
    background-color: #ffffff !important;
}

ul.nav-menu > li.open > button,
ul.nav-menu > li > button:hover,
ul.nav-menu > li > button:focus,
ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
    background-color: #ffffff;
    color: #000;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before,
ul.nav-menu li.menu.open > button:before,
ul.nav-menu li.menu > button:focus:before,
ul.nav-menu li.menu li > button:hover:before {
    background-color: transparent;
    color: #000;
}

ul.nav-menu li ul {
    background-color: #ffffff;
}

.light_gradient {
    background-image: linear-gradient(#f9edef, #ffffff);
}

.light_gradient_reverse {
    background-image: linear-gradient(#ffffff, #f9edef);
}

.text-color-dark {
    color: #444444 !important;
}

.card-height {
    height: 95%;
}

@media screen and (max-width: 770px) {
    .titlefontsize {
        font-size: 25px !important;
        line-height: 35px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
    }
    .partner_heading {
        font-size: 30px !important;
        line-height: 35px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
    .card_margin {
        margin: 0px;
    }
}

@media (min-width: 770px) {
    .titlefontsize {
        font-size: 35px !important;
        line-height: 50px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
    }
    .partner_heading {
        font-size: 40px !important;
        line-height: 50px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
    .card_margin {
        margin: 0px 100px;
    }
    .convenien_overlay_left {
        background-image: linear-gradient(to right, #fff6cd 30%, #fbfbfb17 30%);
    }

    .convenien_overlay_right {
        background-image: linear-gradient(to left, #fff6cd 30%, #fbfbfb17 30%);
    }

    .energy_overlay_left {
        background-image: linear-gradient(to right, #e8f9ed 30%, #fbfbfb17 30%);
    }

    .energy_overlay_right {
        background-image: linear-gradient(to left, #e8f9ed 30%, #fbfbfb17 30%);
    }
}

.logo_color {
    color: #be1e2d !important;
}

.slick-dots li.slick-active button:before {
    border-color: #be1e2d;
}

#footer .navbar-nav li a,
#footer .navbar-nav li {
    display: inline-block;
    padding: 3px 0 !important;
    color: black;
    font-weight: 600;
}

#footer a:hover,
#footer li:hover {
    color: #be1e2d !important;
}

.font_size_45 {
    font-size: 45px !important;
}

.homebg {
    background-image: linear-gradient(to right, #be1e2d, #6e59af);
}

.home_overlay {
    background: rgba(0, 0, 0, 0.4);
}

.overlay4 {
    background: rgba(0, 0, 0, 0.4);
}

.overlay5 {
    background: rgba(0, 0, 0, 0.5);
}

.overlay6 {
    background: rgba(0, 0, 0, 0.6);
}

.react-tel-input .form-control + div:before {
    width: 60px !important;
    color: #6c757d !important;
    content: "Phone *" !important;
}

.react-tel-input .form-control {
    height: auto;
    width: 100% !important;
}

.bannerstyle {
    padding: 100px 0;
}

.project_heading {
    line-height: 20px;
    position: relative;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.project_desc {
    line-height: 20px;
    max-height: 60px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.home_form .MuiInputBase-input,
.home_form .MuiInputBase-root,
.home_form .react-tel-input .form-control {
    background: #f8e8e9;
}

.home_form .react-tel-input .form-control + div:before {
    background: #f8e8e9;
    border-radius: 5px;
}

.subscribe_form .MuiInputBase-input,
.subscribe_form .MuiInputBase-root,
.subscribe_form .react-tel-input .form-control {
    background: #e8eaf6;
}

.subscribe_form .react-tel-input .form-control + div:before {
    background: #e8eaf6;
    border-radius: 5px;
}

.home_form .MuiFormLabel-root.Mui-focused {
    color: #be1e2d;
}

.home_form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #be1e2d;
}

.home_form .react-tel-input .form-control:focus {
    border-color: #be1e2d;
    box-shadow: 0 0 0 1px #be1e2d;
}

.home_form .react-tel-input .form-control:focus + div:before {
    color: #be1e2d !important;
}

.home_slider.carousel-control-next,
.home_slider.carousel-control-prev {
    width: 40px;
    height: 40px;
    opacity: 0.1;
    border-radius: 50%;
    background: #be1e2d !important;
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.home_slider.carousel-control-next:hover,
.home_slider.carousel-control-prev:hover {
    opacity: 0.5;
    background: #be1e2d !important;
}

.navbar-nav li a:hover,
.navbar-nav li .nav-link:hover {
    color: #be1e2d !important;
}

.button_outline {
    border: 1px solid #be1e2d !important;
    color: #be1e2d !important;
}

.button_outline:hover {
    background-color: #be1e2d !important;
    color: white !important;
}

.video_slider .carousel-indicators li::before {
    background: #be1e2d;
    top: 60px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.video_slider .carousel-indicators li {
    box-sizing: inherit;
}

.video_slider .carousel-indicators li {
    width: 15px;
}

.video_slider canvas.snapshot-generator,
.home_video canvas.snapshot-generator {
    display: none;
}

.video_slider video.snapshot-generator {
    background-color: #ffffff;
    border-radius: 0.25rem;
    max-width: 800px !important;
    max-height: 400px;
}

.home_video video.snapshot-generator {
    background-color: #ffffff;
    border-radius: 0.25rem;
    width: 100% !important;
    max-height: 500px;
}
.play_button_position {
    border-radius: 5px;
    z-index: 10;
    cursor: pointer;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.play_button_position .zmdi,
.play_button_position svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.image_mw_100 {
    max-width: 100%;
}

.product-heading {
    line-height: 25px;
    height: 50px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: bold;
}

.product-desc {
    line-height: 20px;
    height: 60px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.col-lg-2-0 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.theme_border_color:hover {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.fs_20 {
    font-size: 20px !important;
}

.fs_25 {
    font-size: 25px !important;
}

.fs_30 {
    font-size: 30px !important;
}

.fs_35 {
    font-size: 35px !important;
}

.fs_40 {
    font-size: 40px !important;
}

.text-underline {
    text-decoration: underline;
}

.pink_bg {
    background: #d37d88;
}

.product_slider .slick-slider img {
    width: auto;
    height: 100px;
    margin: auto;
}

.about_gradient {
    background-image: linear-gradient(#dcf0fa, #ffffff);
}

.convenien_card_bg {
    background: #fff6cd;
}

.arrow_style .slick-slider .slick-prev:before,
.arrow_style .slick-slider .slick-next:before {
    color: #efd833;
}

.arrow_style .slick-prev:before,
.arrow_style .slick-next:before {
    font-family: "slick";
    font-size: 35px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.object-fit-cover {
    object-fit: cover !important;
    max-width: 100% !important;
}

.object-fit-contain {
    object-fit: contain !important;
    max-width: 100% !important;
}

.mh-image-300 {
    max-height: 400px;
    max-width: auto;
    width: auto;
}

.footer_style {
    border-top: 1px solid #daddf1 !important;
    padding: 20px;
}

.footer_style .navbar-nav li,
.footer_style .navbar-nav li a {
    display: inline-block;
    padding: 3px 0 !important;
    color: #000;
    font-weight: 600;
}

.f-right .navbar-nav {
    justify-content: flex-end;
    align-items: flex-end;
    float: right;
}

.energy_green_bg {
    background: #e8f9ed;
}

.energy_green_text {
    color: #236d2d !important;
}

.card-pop:hover {
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-box-shadow: 0px 4px 8px 2px rgba(9, 7, 136, 0.5);
    box-shadow: 0px 4px 8px 2px rgba(9, 7, 136, 0.5);
}

.blogimage img{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    transition: 0.5s all ease-in-out;
}

.blogimage:hover img{
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    transform: scale(1.5);
}

.blog-image-height{
    height: 200px;
    object-fit: cover;
}

.blog_heading {
    line-height: 25px;
    position: relative;
    overflow: hidden;
    height: 50px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.img-max-h-400{
    max-height: 400px;
    max-width: 100%;
    object-fit: contain;
}

.tb-post-label::after {
    content: "•";
    color: rgb(181, 181, 181);
    font-size: 18px;
    line-height: 18px;
    margin: 0px 7px;
}